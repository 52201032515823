import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "_store";
import React, { Fragment } from "react";

export { Breadcumb };

function Breadcumb() {
  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  // only show nav when logged in
  if (!authUser) return null;

  return (
    <Fragment>
      <div  className="breadcrumb-area">
        {/* <h1>Dashboard</h1> */}

        <ol  className="breadcrumb">
          <li  className="item">
            <NavLink to={"/"}>
              <i  className="bx bx-home-alt"></i>
            </NavLink>
          </li>

          <li  className="item">Dashboard</li>

          {/* <li  className="item">Blank Page</li> */}
        </ol>
      </div>
    </Fragment>
  );
}
