import { Fragment } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "_store";


export { Client };

function Client() {
  const dispatch = useDispatch();
  const {clientList}  = useSelector((x) => x.user);

  useEffect(() => {
    dispatch(userActions.getClientDetail());
  }, []);


    return (
      <Fragment>
        <div className="float-left"><h2>Client List</h2></div>
        <div className="float-right"><a className="nav-lidnk mx-2 btn btn-danger btn-sm" href="/newclient">New Client<i className="bx bx-plus"></i></a></div>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Mobile</th>
              <th scope="col">Address</th>
            </tr>
          </thead>
          <tbody>
              {Array.isArray(clientList) && clientList.map((d,idx) => (
                  <tr key={idx}>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                    <td>{d.mobile}</td>
                    <td>{d.address}</td>
                    {/* <td>{d.logo}</td> */}
                  </tr>
              ))}
            </tbody>
        </table>
      </Fragment>
    );
  }
  

