import { Fragment } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { homeActions } from '_store';
import { history } from "_helpers";

export { Dashbaord };

function Dashbaord() {
    const dispatch = useDispatch();
    const { statusCount } = useSelector(x => x.home);

    // const { users } = useSelector(x => x.users);
    const  users =[];
    useEffect(() => {
        dispatch(homeActions.getDashboard());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleStatusClick = (status) => {
        history.navigate("/tickets?status=" + status);
    }

    return (
       
        <Fragment>
            <div className="ecommerce-stats-area">
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6 cursor-pointer" onClick={() => handleStatusClick("")}>
                        <div className="single-stats-card-box">
                            <div className="icon">
                                <i className="bx bx-list-ol"></i>
                            </div>
                            <span className="sub-title">New</span>
                            <h3>{statusCount.New} <span className="badge d-none"><i className="bx bx-notepad"></i></span></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6 cursor-pointer" onClick={() => handleStatusClick("open")}>
                        <div className="single-stats-card-box">
                            <div className="icon bg-primary">
                                <i className="bx bx-run"></i>
                            </div>
                            <span className="sub-title">Open</span>
                            <h3>{statusCount.Open} <span className="badge badge-red d-none"><i className="bx bx-notepad"></i></span></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6 cursor-pointer" onClick={() => handleStatusClick("hold")}>
                        <div className="single-stats-card-box">
                            <div className="icon">
                                <i className="bx bx-stop-circle"></i>
                            </div>
                            <span className="sub-title">Hold</span>
                            <h3>{statusCount.Hold}  <span className="badge d-none"><i className="bx bx-up-arrow-alt"></i> 32.1%</span></h3>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6 cursor-pointer" onClick={() => handleStatusClick("closed")}>
                        <div className="single-stats-card-box">
                            <div className="icon bg-success">
                                <i className="bx bx-check-double"></i>
                            </div>
                            <span className="sub-title">Closed</span>
                            <h3>{statusCount.Closed}  <span className="badge d-none"><i className="bx bx-up-arrow-alt"></i> 56.9%</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}
