import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { ticketReducer } from './ticket.slice';
import { usersReducer } from './users.slice';
import { projectReducer } from './project.slice';
import { homeReducer } from './home.slice';


export * from './auth.slice';
export * from './users.slice';
export * from './ticket.slice';
export * from './project.slice';
export * from './home.slice';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: usersReducer,
        ticket: ticketReducer,
        project:projectReducer,
        home: homeReducer
    },
});