import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { projectActions,userActions} from "_store";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

export { NewProject };

function NewProject() {
  const dispatch = useDispatch();
  const users  = useSelector((x) => x.user.users);
  const {clientList}  = useSelector((x) => x.user);
  // const { users } = useSelector(x => x.users);
  // const users = [];
  
//   console.log(users);
  useEffect(() => {
    // have to use for the get dropdown lists
    // dispatch(userActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // dispatch(userActions.newClientDetails());
    dispatch(userActions.getClientDetail());
  }, []);

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm();
  const { errors, isSubmitting } = formState;
//   dispatch(userActions.getClientDetail());

  function onSubmit({ title, description, till, support_hr, utilize_hr, balance_hr, client }) {
    return dispatch(projectActions.newProjectDetails({ title, description, till, support_hr, utilize_hr, balance_hr, client}));
  }
      
    return (
      <div>
          {/* <!-- Start Profile Area --> */}
          <section className="profile-area">
          <div className=""><h2>New Project</h2></div>
              <div className="row">
                  <div className="col-lg-12">
                      <div className="tab-content">
                          <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                              <div className="card user-settings-box mb-30">
                                  <div className="card-body">
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                          <h3><i className='bx bx-user-circle'></i> Personal Info</h3>
      
                                          <div className="row">
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Title</label>
                                                      <input type="text" name="title" {...register("title")} className="form-control"  placeholder="Enter name"/>
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Start</label>
                                                      <input type="date" name="start" {...register('start')} className="form-control" placeholder="Enter your address" />
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Till</label>
                                                      <input type="date" name="till" {...register('till')} className="form-control" placeholder="Enter your address" />
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Support Hour</label>
                                                      <input type="text" name="support_hr" {...register('support_hr')} className="form-control" placeholder="Enter support hour" />
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Utilize Hour</label>
                                                      <input type="text" name="utilize_hr" {...register('utilize_hr')} className="form-control" placeholder="Enter utilize hour" />
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Balance Hour</label>
                                                      <input type="text" name="balance_hr" {...register('balance_hr')} className="form-control" placeholder="Enter balance hour" />
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Client</label>
                                                      <select name="client_id" {...register('client_id')} className="form-control" >
                                                      <option>Select Client</option>
                                                      {Array.isArray(clientList) && clientList.map((d, i) => (
                                                          <option key={i}>{d.name}</option>
                                                        ))}
                                                      </select>
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Description</label>
                                                      <textarea type="text" name="description" {...register('description')} className="form-control" placeholder="Enter description" ></textarea>
                                                      <span className="form-text text-muted d-block">
                                                          <small>If you want to change email please <a href="#" className="d-inline-block">click</a> here.</small>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="btn-box text-right">
                                              <button type="submit" className="submit-btn"><i className='bx bx-save'></i> Save</button>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    )
  }
  

