import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Nav, Sidebar, Breadcumb } from "../_components/index";
import { Route, Routes,Navigate } from "react-router-dom";
import { Dashbaord } from "./Dashboard";
import { Test } from "./Test";
import { NewTicket } from "./NewTicket";
import { TicketList } from "./TicketList";
import { TicketDetail } from "./TicketDetail";
import { Profile } from "./Profile";
import { Client } from "./Client";
import { NewClient } from "./NewClient";
import { ProjectList } from "./ProjectList";
import { NewProject } from "./NewProject";
import { useState } from "react";
import { history } from "_helpers";
export { AppLayout };

function AppLayout() {
  // const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  // const { users } = useSelector(x => x.users);
  // const users = [];

  if (!authUser) history.navigate("/login");
  useEffect(() => {
    // redirect to home if already logged in

    if (!authUser) history.navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // hide-sidemenu-area
  const [hideSidebar, setHideSidebar] = useState("");

  const handleSidebar = () => {
    if (hideSidebar === "") {
      setHideSidebar("hide-sidemenu-area");
    } else {
      setHideSidebar("");
    }
  };

  return (
    <Fragment>
      <Sidebar toggleSidebar={handleSidebar} />
      <div className={"main-content d-flex flex-column " + hideSidebar}>
        <Nav toggleSidebar={handleSidebar} />
        <Breadcumb />
        <div>
          <Routes>
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="dashboard" element={<Dashbaord />} />
            <Route path="newticket" element={<NewTicket />} />
            <Route path="tickets" element={<TicketList />} />
            <Route path="ticket/:id" element={<TicketDetail />} />
            <Route path="profile" element={<Profile />} />
            <Route path="client" element={<Client />} />
            <Route path="newclient" element={<NewClient />} />
            <Route path="project" element={<ProjectList />} />
            <Route path="newproject" element={<NewProject />} />

            <Route path="test" element={<Test />} />
          </Routes>
        </div>
      </div>
    </Fragment>
  );
}
