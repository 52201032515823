import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ticketActions,userActions,projectActions } from "_store";
import { severityList } from '../_helpers'

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

export { NewTicket };

function NewTicket() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { userlist } = useSelector((x) => x.user);
  const { clientList } = useSelector((x) => x.user);
  const { project } = useSelector((x) => x.project);
  // const { users } = useSelector(x => x.users);
  const users = [];
  useEffect(() => {
    // have to use for the get dropdown lists
    //dispatch(userActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(userActions.userDetails());
    dispatch(userActions.getClientDetail());
    dispatch(projectActions.getAll());
  }, []);

  // console.log(userlist);
  const [showAdv, setShowAdv] = useState(false);

  const animatedComponents = makeAnimated();
  const options = [
    { value: "Network", label: "Network" },
    { value: "Troubleshot", label: "Troubleshot" },
    { value: "Printer", label: "Printer" },
    { value: "Network Cable", label: "Network Cable" },
    { value: "LAN Troubleshot", label: "LAN Troubleshot" },
    { value: "New Requirement", label: "New Requirement" },
    { value: "Software", label: "Software" },
  ];
  const assigneList = [
    { value: "Mohamed", label: "Mohamed" },
    { value: "Salih", label: "Salih" },
    { value: "Ahamed", label: "Ahamed" },
    { value: "Aslam", label: "Aslam" },
    { value: "Muktar Ahamed", label: "Muktar Ahamed" },
    { value: "Younus Ahamed", label: "Younus Ahamed" },
  ];
  // const severityList = [
  //   { value: "Urgent", label: "Urgent" },
  //   { value: "High", label: "High" },
  //   { value: "Medium", label: "Medium" },
  //   { value: "Low", label: "Low" },
  // ];

  // const clientList = [
  //   { value: "1", label: "Client One" },
  //   { value: "2", label: "Client Two" }
  // ];
  // const projectList = [
  //   { value: "Network", label: "Network" },
  //   { value: "Software", label: "Software" }
  // ];

  // const { userlist } = this.state;

	let Projectlists = project.length >0 && project.map((item, i) => {
    return {
      label: item.title,
      value: item.id,
      // <option key={i} value={item.id}>{item.name}</option>
    }
  }, this);

  let Userlists = userlist.length >0 && userlist.map((item, i) => {
		return {
      label: item.name,
      value: item.id,
    }
	}, this);

  let ClientLists = clientList.length >0 && clientList.map((item, i) => {
		return {
      label: item.name,
      value: item.id,
    }
	}, this);

  // form validation rules
  const validationSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required"),
    description: Yup.string(),
    tags: Yup.object()
      .shape({
        label: Yup.string().required("Tags is required (from label)"),
        value: Yup.string().required("Tags is required"),
      })
      .nullable(),
    assignee: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),

    priority: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    client: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
    project: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable(),
  });

  const formOptions = {
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  };

  const { register, control, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({
    subject,
    description,
    tags,
    assignee,
    priority,
    client,
    project,
  }) {

    var tagsVal = tags?.value;
    var assigneeVal = assignee?.value;
    var priorityVal = priority?.value;
    var clientVal = client?.value;
    var projectVal = project?.value;
    //return null;
    return dispatch(
      ticketActions.createTicket({
        subject,
        description,
        tagsVal,
        assigneeVal,
        priorityVal,
        clientVal,
        projectVal,
      })
    );
    // return dispatch(authActions.login({ username, password }));
  }

  return (
    <div className="card">
      <h3>New Ticket</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <input
            type="text"
            {...register("subject")}
            className="form-control"
            placeholder="subject"
          />
          {errors.subject ? <small>Error man</small> : ""}
        </div>

        <div className="form-group d-none">
          <textarea
            {...register("description")}
            name="description"
            className="form-control"
            id="txtDesc"
            cols="30"
            rows="3"
            placeholder="Detail"
          ></textarea>
          {errors.description ? <small>Error man</small> : ""}
        </div>

        <div className="form-group">
          <label>Tags</label>
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Select
                // defaultValue={options[0]}
                {...field}
                isClearable
                isSearchable={false}
                className="react-dropdown"
                classNamePrefix="dropdown"
                components={animatedComponents}
                options={options}
              />
            )}
          />
          {/* <Select
            {...register("tags")}
            value={formState.tags}
            onChange={(option) =>
              formState.setFieldValue(formState.tags, option)
            }
            name="tags"
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={options}
          /> */}
        </div>
        <div className="row">
          <div className="col-12 col-md-6 mt-2">
            <div className="form-group">
              <label>Assignee</label>
              <Controller
                name="assignee"
                control={control}
                render={({ field }) => (
                  <Select
                    // defaultValue={options[0]}
                    {...field}
                    isClearable
                    isSearchable={false}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    components={animatedComponents}
                    options= {Userlists}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 mt-2">
            <div className="form-group">
              <label>priority</label>
              <Controller
                name="priority"
                control={control}
                render={({ field }) => (
                  <Select
                    // defaultValue={options[0]}
                    {...field}
                    isClearable
                    isSearchable={false}
                    className="react-dropdown"
                    classNamePrefix="dropdown"
                    components={animatedComponents}
                    options={severityList}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <button
            className="btn btn-sm btn-link"
            onClick={(e) => {
              e.preventDefault();
              setShowAdv(!showAdv);
            }}
          >
            Advanced
          </button>
        </div>

        {showAdv && (
          <div className="row">
            <div className="col-12 col-md-6 mt-2">
              <label>Client</label>
              <div className="form-group">
                <Controller
                  name="client"
                  control={control}
                  render={({ field }) => (
                    <Select
                      // defaultValue={options[0]}
                      {...field}
                      isClearable
                      isSearchable={false}
                      className="react-dropdown"
                      classNamePrefix="dropdown"
                      components={animatedComponents}
                      options={ClientLists}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 mt-2">
              <div className="form-group">
                <label>Project</label>
                <Controller
                  name="project"
                  control={control}
                  render={({ field }) => (
                    <Select
                      // defaultValue={options[0]}
                      {...field}
                      isClearable
                      isSearchable={false}
                      className="react-dropdown"
                      classNamePrefix="dropdown"
                      components={animatedComponents}
                      options={Projectlists}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}

        <div className="form-group mb-0 text-right mt-4">
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-danger"
          >
            {isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Submit <i className="bx bx-send"></i>
          </button>
        </div>
      </form>
    </div>
  );
}
