import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history,httpClient } from '_helpers';
import { ToastContainer, toast } from "react-toastify";

// create slice

const name = 'project';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const projectActions = { ...slice.actions, ...extraActions };
export const projectReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        projectList:[],
        project: {},
        error:null
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    // alert(baseUrl);
    // console.log(baseUrl);
    return {
        getAll: getAll(),
        // userDetails: userDetails(),
        // getuserDetail:getuserDetail(),
        // getClientDetail:getClientDetail(),
        newProjectDetails:newProjectDetails(),
    };    

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await httpClient.get(`${baseUrl}/getproject`)
        );
    }

    function userDetails() {
        return createAsyncThunk(
            `${name}/updateDetails`,
            async ({firstname, email, phoneno, password, companyname }) => await httpClient.post(`${baseUrl}/getDetails`, { firstname, email, phoneno, password, companyname })
        );
    }

    function getuserDetail() {
        return createAsyncThunk(
          `${name}/getuserDetail`,
          async ({ userId }) =>
            await httpClient.get(`${baseUrl}/getUserById/${userId}`)
        );
    }

    function getClientDetail() {
        // alert(baseUrl+'/getClientDetail');
        return createAsyncThunk(
          `${name}/getClient`,
          async () =>
            await httpClient.get(`${baseUrl}/getClient`)
        );
    }

    function newProjectDetails() {
        // alert(baseUrl+'/getClientDetail');
        return createAsyncThunk(
          `${name}/newClient`,
          async ({title, description, till, support_hr, utilize_hr, balance_hr, client_id}) =>
            await httpClient.post(`${baseUrl}/newProject`,{title, description, till, support_hr, utilize_hr, balance_hr, client_id})
        );
    }
}

function createExtraReducers() {
    return {
        ...getAll(), 
        // ...userDetails(), 
        // ...getUserDetail(),...clientDetail(),
        ...newProjectDetails(),
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.project = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.project = action.payload['projects'];
            },
            [rejected]: (state, action) => {
                state.project = { error: action.error };
            }
        };
    }

    function userDetails() {
        var { pending, fulfilled, rejected } = extraActions.userDetails;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;
                
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;

                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                window.history.navigate(from);
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }

    function getUserDetail() {
        var { pending, fulfilled, rejected } = extraActions.getuserDetail;
        return {
          [pending]: (state) => {
            state.users = { loading: true };
          },
          [fulfilled]: (state, action) => {
            state.users = action.payload["users"];
          },
          [rejected]: (state, action) => {
            state.users = { error: action.error };
          },
        };
      }

    function clientDetail() {
        var { pending, fulfilled, rejected } = extraActions.getClientDetail;
        return {
            [pending]: (state) => {
            state.clientList = { loading: true };
            },
            [fulfilled]: (state, action) => {
            state.clientList = action.payload["clients"];
            },
            [rejected]: (state, action) => {
            state.clientList = { error: action.error };
            },
        };
    }

    function newProjectDetails() {
        var { pending, fulfilled, rejected } = extraActions.newProjectDetails;
        return {
            [pending]: (state) => {
            state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
            state.users = action.payload["project"];
            toast.success("Project Created Successfully");
            history.navigate("/project");
            },
            [rejected]: (state, action) => {
            state.users = { error: action.error };
            },
        };
    }
    
}
