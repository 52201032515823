import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { projectActions } from "_store";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

export { ProjectList };

function ProjectList() {
  const dispatch = useDispatch();
  const projectlist  = useSelector((x) => x.project.project);
  // const { users } = useSelector(x => x.users);
  // const users = [];
  
  // console.log(listItems);
  useEffect(() => {
    // have to use for the get dropdown lists
    // dispatch(userActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(projectActions.getAll());
  }, []);


    return (
      <Fragment>
        <div className="float-left"><h2>Project List</h2></div>
        <div className="float-right"><a className="nav-lidnk mx-2 btn btn-danger btn-sm" href="/newproject">New Project<i className="bx bx-plus"></i></a></div>
        <table className="table table-bordered">
          <thead>
            <tr>
              {/* <th scope="col">#</th> */}
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Till</th>
              <th scope="col">Support Hour</th>
              <th scope="col">Utilized Hour</th>
              <th scope="col">Balance Hour</th>
              <th scope="col">Created By</th>
            </tr>
          </thead>
          <tbody>
              {Array.isArray(projectlist) && projectlist.map((d, i) => (
                  <tr key={i}>
                    <td>{d.title}</td>
                    <td>{d.description}</td>
                    <td>{d.till}</td>
                    <td>{d.support_hr}</td>
                    <td>{d.utilized_hr}</td>
                    <td>{d.balance_hr}</td>
                    <td>{d.created_by}</td>
                    {/* <td>{d.logo}</td> */}
                  </tr>
              ))}
            </tbody>
        </table>
      </Fragment>
      
      // <fragment>
      //   <div className="row">
      //   {/* {clientList.map(name => <h2>{clientList}</h2>)} */}
      //     <div className="col-md-12">
      //       <table className="table">
      //         <thead>
      //           <tr>
      //             <th scope="col">#</th>
      //             <th scope="col">First Name</th>
      //             <th scope="col">Last Name</th>
      //             <th scope="col">Username</th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           <tr>
      //             <td>1</td>
      //             <td>Mark</td>
      //             <td>Otto</td>
      //             <td>@mdo</td>
      //           </tr>
      //           <tr>
      //             <td>2</td>
      //             <td>Jacob</td>
      //             <td>Thornton</td>
      //             <td>@fat</td>
      //           </tr>
      //           <tr>
      //             <td>3</td>
      //             <td colSpan={2}>Larry the Bird</td>
      //             <td>@twitter</td>
      //           </tr>
      //         </tbody>
      //       </table>
      //     </div>
      //   </div>
      // </fragment>
    );
  }
  

