import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from '_store';

export { Test };

function Test() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector(x => x.auth);
    // const { users } =  useSelector(x => x.users);
    const  users  =  [];

    useEffect(() => {
        //dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
         <h1>TEst componet here</h1>
        </div>
    );
}
