import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, httpClient } from "_helpers";
import { ToastContainer, toast } from "react-toastify";

// create slice

const name = "user";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const usersReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    userlist: [],
    clientList: [],
    users: {},
    error: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;
  // alert(baseUrl);
  console.log(baseUrl);
  return {
    getAll: getAll(),
    updateUserDetails: updateUserDetails(),
    userDetails: userDetails(),
    getuserDetail: getuserDetail(),
    getClientDetail: getClientDetail(),
    newClientDetails: newClientDetails(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/getAll`,
      async () => await httpClient.get(baseUrl)
    );
  }

  function updateUserDetails() {
    return createAsyncThunk(
      `${name}/updateDetails`,
      async ({ firstname, email, phoneno, password, companyname }) =>
        await httpClient.post(`${baseUrl}/getDetails`, {
          firstname,
          email,
          phoneno,
          password,
          companyname,
        })
    );
  }

  function userDetails() {
    return createAsyncThunk(
      `${name}/userDetails`,
      async () => await httpClient.post(`${baseUrl}/getUserDetails`)
    );
  }

  function getuserDetail() {
    return createAsyncThunk(
      `${name}/getuserDetail`,
      async ({ userId }) =>
        await httpClient.get(`${baseUrl}/getUserById/${userId}`)
    );
  }

  function getClientDetail() {
    // alert(baseUrl+'/getClientDetail');
    return createAsyncThunk(
      `${name}/getClient`,
      async () => await httpClient.get(`${baseUrl}/getClient`)
    );
  }

  function newClientDetails() {
    // alert(baseUrl+'/getClientDetail');
    return createAsyncThunk(
      `${name}/newClient`,
      async ({ name, email, mobile, address }) =>
        await httpClient.post(`${baseUrl}/newClient`, {
          name,
          email,
          mobile,
          address,
        })
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...updateUserDetails(),
    ...getUserDetail(),
    ...clientDetail(),
    ...newClientDetails(),
    ...userDetails(),
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.users = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload["userlist"];
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error };
      },
    };
  }

  function updateUserDetails() {
    var { pending, fulfilled, rejected } = extraActions.updateUserDetails;
    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        const user = action.payload;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
        state.user = user;

        // get return url from location state or default to home page
        const { from } = history.location.state || { from: { pathname: "/" } };
        window.history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function getUserDetail() {
    var { pending, fulfilled, rejected } = extraActions.getuserDetail;
    return {
      [pending]: (state) => {
        state.users = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload["users"];
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error };
      },
    };
  }

  function userDetails() {
    var { pending, fulfilled, rejected } = extraActions.userDetails;
    return {
      [pending]: (state) => {
        state.userlist = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.userlist = action.payload["users"];
      },
      [rejected]: (state, action) => {
        state.userlist = { error: action.error };
      },
    };
  }

  function clientDetail() {
    var { pending, fulfilled, rejected } = extraActions.getClientDetail;
    return {
      [pending]: (state) => {
        state.clientList = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.clientList = action.payload["clients"];
      },
      [rejected]: (state, action) => {
        state.clientList = { error: action.error };
      },
    };
  }

  function newClientDetails() {
    var { pending, fulfilled, rejected } = extraActions.newClientDetails;
    return {
      [pending]: (state) => {
        state.users = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.users = action.payload["client"];
        toast.success("Client Created Successfully");
        history.navigate("/client");
      },
      [rejected]: (state, action) => {
        state.users = { error: action.error };
        toast.error(action.error);
      },
    };
  }
}
