import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Fragment, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { history } from "_helpers";
import { PrivateRoute } from "_components";
// import { Home } from 'home';
// import { Login } from 'login';
import { Login, Register } from "pages";
import { AppLayout } from "pages/AppLayout";
export { App };

function App() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  // useEffect(() => {
  //   loadScript(`${process.env.PUBLIC_URL}/assets/js/vendors.min.js`)
  //   setTimeout(() => {
  //     setTimeout(() => {
  //       // setLoading(false)
  //     }, 500)
  //     loadScript(`${process.env.PUBLIC_URL}/assets/js/custom.js`)
  //   }, 200)
  // }, [])

  return (
    <Fragment>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<PrivateRoute><AppLayout/></PrivateRoute>} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Fragment>
  );
}


// const loadScript = (src) => {
//   return new Promise(function (resolve, reject) {
//     var script = document.createElement('script')
//     script.src = src
//     script.addEventListener('load', function () {
//       resolve()
//     })
//     script.addEventListener('error', function (e) {
//       reject(e)
//     })
//     document.body.appendChild(script)
//     document.body.removeChild(script)
//   })
// }

 