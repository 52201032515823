import { useSelector } from "react-redux";
import React, { Fragment, useState } from "react";

import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

export { Sidebar };

function Sidebar({ toggleSidebar }) {
  const authUser = useSelector((x) => x.auth.user);
  // const dispatch = useDispatch();
  // const logout = () => dispatch(authActions.logout());
  const [menuActive, setMenuActive] = useState("");
  const [humberStatus, setHumberStatus] = useState("");

  const menuActiveVal = {
    active: "",
    deactive: "toggle-sidemenu-area",
  };
  // only show nav when logged in
  if (!authUser) return null;

  const handleMenuChange = () => {
    if (menuActive === menuActiveVal.active) {
      setMenuActive(menuActiveVal.deactive);
      setHumberStatus("active");
    } else {
      setMenuActive(menuActiveVal.active);
      setHumberStatus("");
    }
    toggleSidebar();
  };

  // active-sidemenu-area

  
  return (
    <Fragment>
      <div className={`sidemenu-area ${menuActive}`}>
        <div className="sidemenu-header">
          <a href="/" className="navbar-brand d-flex align-items-center">
            {/* <img src="assets\img\small-logo.png" alt="image" /> */}
            <span>
              <strong className="text-secondary">FIP</strong>
            </span>
            <span className="text-danger">
              {" "}
              <strong className="text-danger">Desk</strong>{" "}
            </span>  
          </a>

          <div
            className={"burger-menu d-none d-lg-block " + humberStatus}
            onClick={handleMenuChange}
          >
            <span className="top-bar"></span>
            <span className="middle-bar"></span>
            <span className="bottom-bar"></span>
          </div>

          <div
            className={
              "responsive-burger-menu d-block d-lg-none " + humberStatus
            }
            onClick={handleMenuChange}
          >
            <span className="top-bar"></span>
             <span className="middle-bar"></span>
            <span className="bottom-bar"></span>
          </div>
        </div>

        <div className="sidemenu-body">
          <ul
            className="sidemenu-nav metisMenu h-100"
            id="sidemenu-nav"
            data-simplebar=""
          >
            {/* <li className="nav-item-title">Main</li> */}

            <li className="nav-item-title">Menu</li>

            <SidebarLinkList to="/dashboard" title="Dashboard"  icon="bx bx-home" />
            <SidebarLinkList to="/tickets" title="Tickets"   icon="bx bx-book-content"/>
            {/* <SidebarLinkList to="/test" title="Test"  icon="bx bx-pen" /> */}
            <SidebarLinkList to="/client" title="Client"  icon="bx bx-book-content" />
            <SidebarLinkList to="/project" title="Project"  icon="bx bx-book-content" />

           
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

function SidebarLinkList({ children, to, title, icon, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? "nav-item mm-active" : "nav-item"}>
      <NavLink to={to} className="nav-link">
        <span className="icon">
          <i className={icon}></i>
        </span>
        <span className="menu-title">{title}</span>
      </NavLink>
    </li>
  );
}
