export const severityList = [
  { value: "Urgent", label: "Urgent", color: "error" },
  { value: "High", label: "High", color: "warning" },
  { value: "Medium", label: "Medium", color: "info" },
  { value: "Low", label: "Low", color: "default" },
];

export const statusList = [
  { value: "New", label: "New", color: "danger" },
  { value: "Open", label: "Open", color: "primary" },
  { value: "Hold", label: "Hold", color: "warning" },
  { value: "Closed", label: "Closed", color: "success" },
];
