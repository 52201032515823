import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Route, Link, Routes, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions,userActions } from '_store';

export { Profile };

function Profile() {

  const dispatch = useDispatch();
  const userList = useSelector((x) => x.user.users);
  const authError = useSelector((x) => x.auth.error);

  const appUser = useSelector((x) => x.auth.user);
console.log(userList.email);
// const { id: userId } = useParams();

// console.log(ticketId, "  >>>>>>>>>>.");

//const ticketId = id//["id"];




useEffect(() => {

    let userId = appUser.id;
    // console.log(userId);
  dispatch(userActions.getuserDetail({ userId }));
}, []);

  const handleRegister = () => {
    history.navigate("/register");
  };

  // form validation rules
//   const validationSchema = Yup.object().shape({
//     username: Yup.string().required("Username is required"),
//     password: Yup.string().required("Password is required"),
//     firstname: Yup.string(),
//   });
//   const formOptions = { resolver: yupResolver(validationSchema) };

// const baseUrl = `${process.env.REACT_APP_API_URL}/ticket`;

// fetch('baseUrl')
//                         .then(response => {
//                             return response.json();
//                         }).then(result => {
//                             console.log(result);
//                             this.setState({
//                                 products:result
//                             });
//                         });

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm();
  const { errors, isSubmitting } = formState;

  function onSubmit({ userId,firstname, email, phoneno, password, companyname }) {
    return dispatch(userActions.updateUserDetails({ userId,firstname, email, phoneno, password, companyname }));
  }

    return (
        <div>
            {/* <!-- Start Profile Area --> */}
            <section className="profile-area">
                <div className="profile-header mb-30">
                    <div className="user-profile-images">
                        <img src="assets\img\profile-banner.jpg" className="cover-image" alt="image" />

                        <div className="upload-cover-photo">
                            <a href="#"><i className='bx bx-camera'></i> <span>Update Cover Photo</span></a>
                        </div>

                        <div className="profile-image">
                            <img src="assets\img\user1.jpg" alt="image" />

                            <div className="upload-profile-photo">
                                <a href="#"><i className='bx bx-camera'></i> <span>Update</span></a>
                            </div>
                        </div>

                        <div className="user-profile-text">
                            <h3>{userList.name}</h3>
                            <span className="d-block">Developer</span>
                        </div>
                    </div>

                    <div className="user-profile-nav">
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false">Settings</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                                <div className="card user-settings-box mb-30">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <h3><i className='bx bx-user-circle'></i> Personal Info</h3>
        
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label>User Name</label>
                                                        <input type="text" name="uname" className="form-control" value={userList.email} placeholder="Enter first name" readOnly= {true}/>
                                                        <input type="hidden" name="uid" className="form-control" value={appUser.id} placeholder="Enter first name" readOnly= {true}/>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>First Name</label>
                                                        <input type="text" name="firstname" {...register('firstname')} value={userList.firstname}  className="form-control"  placeholder="Enter first name" />
                                                    </div>
                                                </div>
        
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Email Address</label>
                                                        <input type="email" name="email" {...register('email')} value={userList.email} className="form-control" placeholder="Enter email address" readOnly= {true} />
                                                        <span className="form-text text-muted d-block">
                                                            <small>If you want to change email please <a href="#" className="d-inline-block">click</a> here.</small>
                                                        </span>
                                                    </div>
                                                </div>
        
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        <input type="text" name="phoneno" {...register('phoneno')} value={userList.mobile} className="form-control" placeholder="Enter phone number" />
                                                        <span className="form-text text-muted d-block">
                                                            <small>If you want to change phone number please <a href="#" className="d-inline-block">click</a> here.</small>
                                                        </span>
                                                    </div>
                                                </div>
        
                                                {/* <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input type="password" name="password" {...register('password')} value={userList.password} className="form-control" placeholder="Enter password" />
                                                        <span className="form-text text-muted d-block">
                                                            <small>If you want to change password please <a href="#" className="d-inline-block">click</a> here.</small>
                                                        </span>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <h3><i className='bx bx-building'></i> Company Info</h3>
        
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label>Company Name</label>
                                                        <input type="text" name="companyname" {...register('companyname')} value={userList.company} className="form-control" placeholder="Enter company name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="btn-box text-right">
                                                <button type="submit" className="submit-btn"><i className='bx bx-save'></i> Save</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}