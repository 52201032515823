import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Link, Routes, useParams } from "react-router-dom";

import Moment from "moment";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ticketActions } from "_store";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { Chip, Paper } from "@mui/material";

import {severityList, statusList} from '../_helpers'

export { TicketDetail };

function TicketDetail() {
  const dispatch = useDispatch();
  const { detail } = useSelector((x) => x.ticket);
  const { user: authUser } = useSelector((x) => x.auth);

  const { id: ticketId } = useParams();

  useEffect(() => {
    dispatch(ticketActions.getTicketDetail({ ticketId }));
  }, []);

  const [tkStatus, setTkStatus] = useState("New");
  const [tkSeverity, settkSeverity] = useState("High");

  const animatedComponents = makeAnimated();
  const tkStatusList = [
    { value: "New", label: "New" },
    { value: "progress", label: "Progress" },
    { value: "hold", label: "Hold" },
    { value: "closed", label: "Closed" },
  ];

  const severity = [
    { value: "Urgent", label: "Urgent" },
    { value: "High", label: "High" },
    { value: "Medium", label: "Medium" },
    { value: "Low", label: "Low" },
  ];



  const getSeverityColor = (severity) => {
    return severity ? severityList.filter(sl => sl.value == severity)[0].color : "default";
  };

  const getStatusColor = (status) => {
    return status ? statusList.filter(sl => sl.value == status)[0].color : "default";
  };

  const [severityColor, setSeverityColor] = useState(
    getSeverityColor(detail.severity)
  );

  const [statusColor, setStatusColor] = useState(
    getStatusColor(detail.status)
  );

  // https://www.bootdey.com/snippets/view/bs4-timeline#css

  // form validation rules
  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Please enter the comment"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState, getValues } =
    useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ ticket_id, comment }) {
    dispatch(
      ticketActions.updateComment({
        ticket_id: ticketId,
        comment,
        userId: authUser.id,
      })
    );
    reset({ comment: "" });
    dispatch(ticketActions.getTicketDetail({ ticketId }));
  }

  const handleStatus = (e) => {
    console.log("clicked");
  };

  const handleComment = (e) => {
    console.log("clicked");
  };

  const handleTkStatus = (li) => {
    setTkStatus(li);
  };

  const handleTkSeverity = (li) => {
    settkSeverity(li);
  };

  const handleClose = () => {
    dispatch(
      ticketActions.updateStatus({
        ticket_id: ticketId,
        withCmd: getValues("comment") ? true : false,
        cmd: getValues("comment"),
        status: "Closed",
      })
    );
    dispatch(ticketActions.getTicketDetail({ ticketId }));
  };

  return (
    <Fragment>
      <Paper elevation={1} className="p-3 my-4">
        <div className="d-flex justify-content-between">
          <div>
            <h3 className="text-dark">{detail.subject}</h3>
            <div>
              {detail.tag &&
                detail.tag.split(",").map((li, idx) => {
                  return <Chip key={idx} label={li} size="small" />;
                })}
            </div>
          </div>

          <div className="d-flex">
            <div className={`mx-1 text-${statusColor}`}>
              <label className="small mb-0" htmlFor="">
                Status
              </label>
              <h6 className={`font-weight-bold text-${statusColor}`}>{detail.status}</h6>
            </div>
            <div className={`mx-1 text-${severityColor == "error" ? "danger" : severityColor}`}>
              <label className="small mb-0" htmlFor="">
                Severity
              </label>
              <h6 className={`font-weight-bold text-${severityColor == "error" ? "danger" : severityColor}`}>{detail.severity}</h6>
            </div>
          </div>
        </div>
        <hr />
        <div className="row my-2">
          <div className="col-12 col-md-9">
            <div className="card p-0">
              <div className="timeline-bg rounded">
                <ul className="timeline">
                  {detail.log &&
                    detail.log.map((li, idx) => {
                      return (
                        <li key={idx}>
                          <div className="timeline-time">
                            <span className="date">
                              {Moment(li.created_on).format("DD-MM-YYYY")}
                            </span>
                            <span className="time">
                              {Moment(li.created_on).format("hh:mm a")}
                            </span>
                          </div>
                          <div className="timeline-icon">
                            <span></span>
                          </div>
                          <div className="timeline-body">
                            <div className="timeline-header border-0">
                              {/* <span className="userimage d-none">
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                alt=""
                              />
                            </span> */}
                              <span className="username">{li.created_by}</span>
                              <span className="pull-right text-muted ml-1">
                                {li.action}
                              </span>
                            </div>
                            <div className="timeline-content">
                              {li.comment && <p>{li.comment}</p>}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>

                {detail.status != "Closed" && (
                  <Fragment>
                    <div className="input mx-2 mb-5">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group">
                          <input
                            type="hidden"
                            name="ticket_id"
                            {...register("ticket_id")}
                            value={detail.id ?? ""}
                          />
                          <input
                            type="text"
                            name="comment"
                            {...register("comment")}
                            className="form-control rounded-corner"
                            placeholder="Write a comment..."
                          />

                          <span className="input-group-btn p-l-10 ml-2">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-danger f-s-12 rounded-corner"
                            >
                              Comment
                            </button>
                          </span>
                        </div>
                        <small className="text-danger">
                          {" "}
                          {errors.comment?.message}
                        </small>
                      </form>
                    </div>

                    <div className="my-3 text-right">
                      <button
                        className="btn btn-success btn-sm mx-1"
                        onClick={() => handleClose()}
                      >
                        <i className="bx bx-check-double"></i> Close Ticket
                      </button>
                    </div>
                  </Fragment>
                )}

                {detail.status == "Closed" && (
                  <div className="font-weight-bold my-4 text-center text-muted">
                    Ticket has been Closed
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3">
            <div className="card">
              <h6 className="text-gray">Assignee</h6>

              {detail.user?.length > 0 && (
                <>
                  <div className="text-primary">
                    <span>
                      {detail.user[0].name} (
                      <small>{detail.user[0].email}</small>)
                    </span>
                  </div>
                  <div className="text-primary"></div>
                </>
              )}
              {detail.user?.length === 0 && <span className="text-primary">Click to Assign</span>}
            </div>
            <div className="card mt-2">
              <h6 className="text-gray">Client Detail</h6>
              {detail.client && (
                <>
                  <div className="text-primary">
                    <span>{detail.client.name}</span>
                  </div>
                  <div className="text-primary">
                    {/* <small>{detail.client.email}</small> */}
                  </div>
                </>
              )}
              {!detail.client && <span>-</span>}
            </div>
            <div className="card mt-2">
              <h6 className="text-gray">Project Detail</h6>
              {detail.project && (
                <>
                  <div className="text-primary">
                    <span>{detail.project.title}</span>
                  </div>
                  <div className="text-primary">
                    {/* <small>{detail.client.email}</small> */}
                  </div>
                </>
              )}
              {!detail.project && <span>-</span>}
            </div>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
}
