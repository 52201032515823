import axios from "axios";
import { toast } from "react-toastify";
import { store, authActions } from "_store";

const LOADER = "app-loader";

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.interceptors.response.use(
  (resp) => {
    return resp.data;
  },
  (error) => {
    const { config, message } = error;
    // retry while Network timeout or Network Error
    if (message.includes("timeout") || message.includes("Network Error")) {
      const logout = () => store.dispatch(authActions.logout());
      logout();
      toast.error(message);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (req) => {
    const token = authToken();
    const isLoggedIn = !!token;
    if (isLoggedIn) {
      req.headers["Authorization"] = `Bearer ${token}`;
    }

    return req;
  },
  (error) => {
    // loader(false);
    return Promise.reject(error);
  }
);

// function loader(enable) {
//   if (enable) document.body.classList.add(LOADER);
//   else document.body.classList.remove(LOADER);
// }

function authToken() {
  return store.getState().auth.token;
}

export const httpClient = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
