import { Fragment, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { userActions } from "_store";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

export { NewClient };

function NewClient() {
  const dispatch = useDispatch();
  const users  = useSelector((x) => x.user.users);
 
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm();
  const { errors, isSubmitting } = formState;

  function onSubmit({ name, email,mobile, address }) {

    if(!name) {
        toast.error("Please enter the name");
        return;
    }
    if(!mobile) {
        toast.error("Please enter the mobile");
        return;
    }

    return dispatch(userActions.newClientDetails({ name, email, mobile, address }));
  }
      
    return (
      <div>
          {/* <!-- Start Profile Area --> */}
          <section className="profile-area">
          <div className=""><h2>New Client</h2></div>
              <div className="row">
                  <div className="col-lg-12">
                      <div className="tab-content">
                          <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
                              <div className="card user-settings-box mb-30">
                                  <div className="card-body">
                                      <form onSubmit={handleSubmit(onSubmit)}>
                                          <h3><i className='bx bx-user-circle'></i> Personal Info</h3>
      
                                          <div className="row">
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Name</label>
                                                      <input type="text" {...register("name")} className="form-control"  placeholder="Enter name"/>
                                                  </div>
                                              </div>
      
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Email Address</label>
                                                      <input type="email" name="email" {...register('email')} className="form-control" placeholder="Enter email address" />
                                                      {/* <span className="form-text text-muted d-block">
                                                          <small>If you want to change email please <a href="#" className="d-inline-block">click</a> here.</small>
                                                      </span> */}
                                                  </div>
                                              </div>
                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Mobile</label>
                                                      <input type="text" {...register("mobile")} className="form-control"  placeholder="Enter mobile #"/>
                                                  </div>
                                              </div>

                                              <div className="col-lg-6">
                                                  <div className="form-group">
                                                      <label>Address</label>
                                                      <textarea name="companyname" rows={4} {...register('address')} className="form-control" placeholder="Enter your address"></textarea>
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="btn-box text-right">
                                              <button type="submit" className="submit-btn"><i className='bx bx-save'></i> Save</button>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
    )
  }
  

