import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import makeAnimated from "react-select/animated";
import { ticketActions } from "_store";
import { TicketCardComp } from "_components/TicketCardComp";
import {
  useSearchParams,
} from 'react-router-dom';

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";


export { TicketList };

function TicketList() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { tickets } = useSelector((x) => x.ticket);
  const users = [];
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(ticketActions.getTicketByStatus(searchParams.get('status') ? searchParams.get('status') : "" ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showAdv, setShowAdv] = useState(false);

  const [value, setValue] = useState(searchParams.get('status') ? searchParams.get('status') : "" );
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(ticketActions.getTicketByStatus(newValue));
    setSearchParams({status: newValue});
  };

  // const animatedComponents = makeAnimated();
  // const options = [
  //   { value: "Network", label: "Network" },
  //   { value: "Troubleshot", label: "Troubleshot" },
  //   { value: "Printer", label: "Printer" },
  //   { value: "Network Cable", label: "Network Cable" },
  //   { value: "LAN Troubleshot", label: "LAN Troubleshot" },
  //   { value: "New Requirement", label: "New Requirement" },
  //   { value: "Software", label: "Software" },
  // ];
  // const assigneList = [
  //   { value: "Network", label: "Mohamed" },
  //   { value: "Troubleshot", label: "Salih" },
  //   { value: "Printer", label: "Ahamed" },
  //   { value: "Network Cable", label: "Aslam" },
  //   { value: "LAN Troubleshot", label: "Muktar Ahamed" },
  //   { value: "New Requirement", label: "Younus Ahamed" },
  // ];

  // const severityList = [
  //   { value: "High", label: "High" },
  //   { value: "Medium", label: "Medium" },
  //   { value: "Low", label: "Low" },
  // ];

  // const list = [
  //   {
  //     id: "001",
  //     title: "Some issues going on This Project",
  //     severity: "High",
  //     Assigne: "Mohamed Salih",
  //     description: "This is added by salih, and followed by Mohamed",
  //     client: "ABC Company Ltd",
  //     project: "Network",
  //     tags: "Network, Cable changes, new item, others",
  //     status: "Pending",
  //   },
  //   {
  //     id: "002",
  //     title: "Some issues going on Project SSS",
  //     severity: "Medium",
  //     Assigne: "Mohamed",
  //     description: "This is added by salih, and followed by Mohamed",
  //     client: "ABC Company Ltd",
  //     project: "Computer",
  //     tags: "Computer, Cable, new item, others",
  //     status: "Open",
  //   },
  // ];
  return (
    <div className="card1">

      <Paper elevation={2}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab value="" label="All" />
          <Tab value="open" label="Open" />
          {/* <Tab value="progress" label="Progress" /> */}
          <Tab value="hold" label="Hold" />
          <Tab value="closed" label="Closed" />
        </Tabs>
      </Paper>

      <Paper elevation={0} className=" my-3">
        {/* <div className="p-2"></div> */}
        <ul className="p-0" style={{ listStyle: "none" }}>
          {tickets.length > 0 &&
            tickets.map((li, idx) => {
              return (
                <li key={idx}>
                  <TicketCardComp data={li} idx={idx} />
                </li>
              );
            })}

          {tickets.length == 0 && (
            <Paper elevation={0}>
              <h6 className="text-center py-3">Data not found</h6>
            </Paper>
          )}
        </ul>

        {tickets.loading && (
          <div className="spinner-border spinner-border-sm"></div>
        )}
        {tickets.error && (
          <div className="text-danger">
            Error loading Tickets: {tickets.error.message}
          </div>
        )}
      </Paper>
    </div>
  );
}
