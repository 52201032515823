import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch, useStadd } from "react-redux";
import { useState } from "react";
import { authActions } from "_store";
import React, { Fragment } from "react";
import { ListItemIcon, ListItemText, Paper } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import { severityList, statusList } from "_helpers";
import { ticketActions } from "_store";

export { TicketCardComp };

function TicketCardComp({ data, idx }) {
  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSevertity, setSelectedSevertity] = React.useState(
    data.severity
  );

  const getSeverityColor = (severity) => {
    return severity
      ? severityList.filter((sl) => sl.value == severity)[0].color
      : "default";
  };

  const getStatusColor = (status) => {
    return status
      ? statusList.filter((sl) => sl.value == status)[0].color
      : "default";
  };

  const [severityColor, setSeverityColor] = React.useState(
    getSeverityColor(data.severity)
  );

  const [statusColor, setStatusColor] = React.useState(
    getStatusColor(data.status)
  );

  // only show nav when logged in
  if (!authUser) return null;

  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, val) => {
    e.preventDefault();
    setSelectedSevertity(val);
    setSeverityColor(getSeverityColor(val));
    dispatch(
      ticketActions.updateSeverity({
        ticket_id: data.id,
        severity: val,
      })
    );
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <Paper elevation={1}>
        <Link style={{ textDecoration: "none" }} to={"/ticket/" + data.id}>
          <div className="card mt-2 p-3">
            <h4>
              <small className="text-secondary table-secondary px-1 rounded">
                #{idx + 1}
              </small>
              {/* "text-warning border border-warning table-warning px-1 rounded mx-1" */}
              <small
                className={`border px-1 rounded mx-1 border-${statusColor} text-${statusColor} table-${statusColor}`}
              >
                {data.status}
              </small>
              <span>{data.subject}</span>

              <span className="float-right">
                <Chip
                  label={selectedSevertity}
                  color={severityColor}
                  onClick={handleClick}
                  size="small"
                />

                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                  }}
                >
                  {severityList.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      disabled={option.label === selectedSevertity}
                      selected={option.label === selectedSevertity}
                      // className={`text-${option.color}`}
                      onClick={(event) =>
                        handleMenuItemClick(event, option.value)
                      }
                    >
                      <ListItemIcon>
                        <i
                          className={`bx bxs-circle text-${
                            option.color == "error" ? "danger" : option.color
                          }`}
                        ></i>
                      </ListItemIcon>
                      <ListItemText> {option.label}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </span>
            </h4>
            <p className="pb-0">{data.tag}</p>
            <hr className="my-1" />
            <div className="text-secondary small">
              <small>
                <i className="bx bx-time-five"></i> {data.created_on}
              </small>

              {data.comp_id && (
                <span className="ml-3">
                  {" "}
                  Client: <span className="text-primary">{data.client}</span>
                </span>
              )}
              {data.project_id && (
                <span className="ml-3">
                  {" "}
                  Project: <span className="text-primary">{data.project}</span>
                </span>
              )}

              {data.assignee_name && (
                <span
                  title="Assigned User"
                  className="float-right px-1 rounded"
                >
                  <i className="bx bx-user"></i>{" "}
                  <span className="text-primary"> {data.assignee_name}</span>
                </span>
              )}

              {!data.assignee_name && (
                <span className="float-right table-secondary table-secondary border border-secondary px-1 rounded">
                  Not assigned
                </span>
              )}
            </div>
          </div>
        </Link>
      </Paper>
    </Fragment>
  );
}
