import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, httpClient } from "_helpers";
import { ToastContainer, toast } from "react-toastify";

// create slice

const name = "home";
const initialState = createInitialState();
// const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const homeActions = { ...slice.actions, ...extraActions };
export const homeReducer = slice.reducer;

// implementation
function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    statusCount: [],
    error: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/home`;

  return {
    getDashboard: getDashboard()
  };

  function getDashboard() {
    return createAsyncThunk(
      `${name}/getDashboard`,
      async () => await httpClient.get(`${baseUrl}/getDashboard`)
    );
  }
  
}

function createExtraReducers() {
  return {
    ...getDashboard()
  };

  function getDashboard() {
    var { pending, fulfilled, rejected } = extraActions.getDashboard;
    return {
      [pending]: (state) => {
        state.statusCount = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.statusCount = action.payload["statusCount"];
      },
      [rejected]: (state, action) => {
        state.homes = { error: action.error };
      },
    };
  }

}
