import { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";

import { history } from "_helpers";
import { authActions } from "_store";

export { Login };

function Login() {
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authError = useSelector((x) => x.auth.error);

  useEffect(() => {
    // redirect to home if already logged in
    if (authUser) history.navigate("/");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegister = () => {
    history.navigate("/register");
  };

  // form validation rules
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ username, password, }) {
    return dispatch(authActions.login({ username, password }));
  }


  return (
    <Fragment>
      <div className="login-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="login-form">
              <div className="logo">
                {/* <a href="dashboard-analytics.html"><img src="assets\img\logo.png" alt="image" /></a> */}
              </div>

              <h2>Welcome</h2>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input
                    type="text"
                    {...register("username")}
                    className={`form-control ${
                      errors.username ? "is-invalid" : ""
                    }`}
                    name="username"
                    placeholder="Email"
                  />
                  <span className="label-title">
                    <i className="bx bx-user"></i>
                  </span>
                  <div className="invalid-feedback">
                    {errors.username?.message}
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    {...register("password")}
                    className={`form-control ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    name="password"
                    placeholder="Password"
                  />
                  <span className="label-title">
                    <i className="bx bx-lock"></i>
                  </span>
                  <div className="invalid-feedback">
                    {errors.password?.message}
                  </div>
                </div>

                <button disabled={isSubmitting} className="login-btn">
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Login
                </button>
                {authError && (
                  <div className="alert alert-danger mt-3 mb-0">
                    {authError.message}
                  </div>
                )}

                {/* <p className="mb-0">
                  Don’t have an account? <a href="/register">Sign Up</a>
                </p> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
