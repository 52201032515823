import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { history, httpClient } from "_helpers";
import { ToastContainer, toast } from "react-toastify";

// create slice

const name = "ticket";
const initialState = createInitialState();
// const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports
export const ticketActions = { ...slice.actions, ...extraActions };
export const ticketReducer = slice.reducer;

// implementation
function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    tickets: [],
    detail: {},
    ucomment: {},
    error: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/ticket`;

  return {
    getAll: getAll(),
    getTicketByStatus: getTicketByStatus(),
    createTicket: createTicket(),
    getTicketDetail: getTicketDetail(),
    updateComment: updateComment(),
    updateSeverity: updateSeverity(),
    updateStatus: updateStatus(),
  };

  function getAll() {
    return createAsyncThunk(
      `${name}/ticket`,
      async () => await httpClient.get(baseUrl)
    );
  }

  function getTicketByStatus() {
    return createAsyncThunk(
      `${name}/getTicketByStatus`,
      async (status) =>
        await httpClient.get(
          `${baseUrl}/getTicketByStatus?status=${status == null ? "" : status}`
        )
    );
  }

  function createTicket() {
    //         `id`,
    // `subject`,
    // `severity`,
    // `purpose`,
    // `tag`,
    // `status`,
    // `assignee`,
    // `comp_id`,
    // `project_id`
    return createAsyncThunk(
      `${name}/createTicket`,
      async ({
        subject,
        description,
        tagsVal,
        assigneeVal,
        priorityVal,
        clientVal,
        projectVal,
      }) =>
        await httpClient.post(`${baseUrl}`, {
          subject,
          description,
          tag: tagsVal,
          assignee: assigneeVal,
          severity: priorityVal,
          status: "New",
          comp_id: clientVal,
          project_id: projectVal,
        })
    );
  }

  function getTicketDetail() {
    return createAsyncThunk(
      `${name}/getTicketDetail`,
      async ({ ticketId }) =>
        await httpClient.get(`${baseUrl}/getTicketByID/${ticketId}`)
    );
  }

  function updateComment() {
    return createAsyncThunk(
      `${name}/updateComment`,
      async ({ ticket_id, comment, userId }) =>
        await httpClient.post(`${baseUrl}/updateComment`, {
          ticket_id,
          comment,
          userId,
        })
    );
  }

  function updateSeverity() {
    return createAsyncThunk(
      `${name}/updateSeverity`,
      async ({ ticket_id, severity }) =>
        await httpClient.post(`${baseUrl}/updateSeverity`, {
          id: ticket_id,
          severity,
        })
    );
  }
  function updateStatus() {
    return createAsyncThunk(
      `${name}/updateStatus`,
      async ({ ticket_id, withCmd, cmd, status }) =>
        await httpClient.post(`${baseUrl}/updateStatus`, {
          id: ticket_id,
          withCmd,
          cmd : cmd ? cmd : " ",
          status,
        })
    );
  }
}

function createExtraReducers() {
  return {
    ...getAll(),
    ...getTicketByStatus(),
    ...createTicket(),
    ...getTicketDetail(),
    ...updateComment(),
    ...updateSeverity(),
    ...updateStatus()
  };

  function getAll() {
    var { pending, fulfilled, rejected } = extraActions.getAll;
    return {
      [pending]: (state) => {
        state.tickets = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.tickets = action.payload["tickets"];
      },
      [rejected]: (state, action) => {
        state.tickets = { error: action.error };
      },
    };
  }

  function getTicketByStatus() {
    var { pending, fulfilled, rejected } = extraActions.getTicketByStatus;
    return {
      [pending]: (state) => {
        state.tickets = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.tickets = action.payload["tickets"];
      },
      [rejected]: (state, action) => {
        state.tickets = { error: action.error };
      },
    };
  }

  function createTicket() {
    var { pending, fulfilled, rejected } = extraActions.createTicket;
    return {
      [pending]: (state) => {
        state.error = null;
        state.detail = null;
      },
      [fulfilled]: (state, action) => {
        state.detail = action.payload;
        toast.success("Ticket Created Successfully");
        history.navigate("/tickets");
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function getTicketDetail() {
    var { pending, fulfilled, rejected } = extraActions.getTicketDetail;
    return {
      [pending]: (state) => {
        state.detail = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.detail = action.payload["ticket"];
      },
      [rejected]: (state, action) => {
        state.detail = { error: action.error };
      },
    };
  }

  function updateComment() {
    var { pending, fulfilled, rejected } = extraActions.updateComment;
    return {
      [pending]: (state) => {
        state.ucomment = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.ucomment = action.payload["ticket_log"];
      },
      [rejected]: (state, action) => {
        state.ucomment = { error: action.error };
      },
    };
  }
  function updateSeverity() {
    var { pending, fulfilled, rejected } = extraActions.updateSeverity;
    return {
      [pending]: (state) => {
        state.ucomment = { loading: true };
      },
      [fulfilled]: (state, action) => {
        // state.ucomment = action.payload["ticket_log"];
      },
      [rejected]: (state, action) => {
        // state.ucomment = { error: action.error };
      },
    };
  }
  function updateStatus() {
    var { pending, fulfilled, rejected } = extraActions.updateStatus;
    return {
      [pending]: (state) => {
        state.ucomment = { loading: true };
      },
      [fulfilled]: (state, action) => {
        // state.ucomment = action.payload["ticket_log"];
      },
      [rejected]: (state, action) => {
        // state.ucomment = { error: action.error };
      },
    };
  }
}
